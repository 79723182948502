.custom-card-1__card-body.card-body{
    width: 100%;
    height: 100%;
    aspect-ratio: 637/376;
    background-color: #F5F5F5;
    border-radius: 11px;
    position: relative;
    padding: 15px;
    display: grid;
    gap: calc(100vh/194);
    gap: 5px;
    grid-template-columns: 100%;
    grid-template-rows: 25px max-content 3fr 1fr;
    align-items: center;
}
.custom-card-1__card-body.card-body[data-has-icon="true"]:before {
    content: "";
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='42' viewBox='0 0 25 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.7463 10.5C21.7783 10.5 20.999 11.2793 20.999 12.2473V29.7445C20.999 34.5762 17.0861 38.4973 12.2463 38.4973C7.40645 38.4973 3.49355 34.5762 3.49355 29.7445V8.74453C3.49355 5.84883 5.83965 3.49453 8.74355 3.49453C11.6475 3.49453 13.9936 5.84883 13.9936 8.74453V26.25C13.9936 27.218 13.2061 27.9973 12.2463 27.9973C11.2865 27.9973 10.499 27.218 10.499 26.25V12.2473C10.499 11.2793 9.71152 10.5 8.75176 10.5C7.78379 10.5 7.00449 11.2793 7.00449 12.2473V26.25C7.00449 29.1539 9.35059 31.5 12.2545 31.5C15.1584 31.5 17.5045 29.1539 17.5045 26.25V8.75273C17.4963 3.92109 13.5752 0 8.74355 0C3.91191 0 -0.000976562 3.92109 -0.000976562 8.75273V29.7527C-0.000976562 36.5203 5.48691 42 12.2463 42C19.0057 42 24.4936 36.5121 24.4936 29.7527V12.2473C24.4936 11.2793 23.7143 10.5 22.7463 10.5Z' fill='%23FF6539'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 30px;
    height: 30px;
    left: 20px;
    top: -15px;
}
.card-body__dynamic-new-tab.dynamic-new-tab a {
    height: 20px;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .custom-card-1__card-body.card-body{
        padding: 10px 15px;
        grid-template-rows: 15px max-content 3fr 1fr;
    }
    .custom-card-1__card-body.card-body[data-has-icon="true"]:before{
        width: 24px;
        height: 24px;
        left: 16px;
        top: -12px;
    }
}