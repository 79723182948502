.hero__media_animation__box{
    position: relative;
    width: 143px;
    height: 143px;
    border-radius: 50%;
    contain: content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero__media_animation__box_square{
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    contain: content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 5px;
}

.hero.minimized .hero__media_animation__box{
    width: 45px;
    height: 45px;
}

.hero__media_animation{
    position: relative;
    width: 135px;
    height: 135px;
    /*margin-bottom: 20px;*/
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 2;
}
.hero__media_animation_square{
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    /*margin-bottom: 20px;*/
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 2;
    border-radius: 5px;

}
.hero.minimized .hero__media_animation{
    width: 40px;
    height: 40px;
}
.hero__media_animation_square img{
    z-index: 2;
    position: relative;
    width: 100%;
    height: 100%;
    border: 5px solid white;
    transition: 0.3s;
    border-radius: 5px;
    object-fit: contain;
}
.hero.minimized .hero__media_animation_square img{
    border: 2px solid white;
}
.hero__media_animation img{
    z-index: 2;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid white;
    transition: 0.3s;
}
.hero.minimized .hero__media_animation img{
    border: 2px solid white;
}
.hero__media__liner-gradien{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba(255,222,90,1) 0%, rgba(234,145,188,1) 46%, rgba(235,87,44,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    z-index: 1;
}


.hero__media_animation__box:hover .hero__media__liner-gradien{
    animation: rotation 2.5s infinite ease;
}
.hero__media_animation__box:hover .hero__media_animation img{
    opacity: 80%;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
@media screen and (min-width: 900px) and (max-width: 3000px) {
    /*.hero{*/
    /*    zoom: 100%;*/
    /*}*/
    .hero__media_animation__box{
        width: 100px;
        height: 100px;
    }
    .hero__media_animation{
        width: 95px;
        height: 95px;
    }
    .hero__descr{
        font-size: 14px;
    }
}

