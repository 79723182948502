.forgot-subtitle {
    font-size: 36px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 10px;
    font-weight: normal;
    letter-spacing: 5px;
    text-align: center;
}
.forgot-descr, .forgot-email {
    font-size: 18px;
    text-align: center;
    color: #fff;
}
.forgot-descr {
    max-width: 500px;
    margin-bottom: 40px;
}
.forgot-email {
    margin-bottom: 20px;
}
