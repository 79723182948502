.account-content-email-send {
    flex-grow: 1;
    min-width: 520px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(100vw/1.50);
    margin-top: 40px;
}
.text-description{
    max-width: '100%';
    font-size: 20px;
}
.check-text {
    font-size: 35px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    letter-spacing: 2px;
    margin-top: 40px;
}
.check-info-text{
    text-transform: unset!important;
    margin-top: 70px!important;
    opacity: 1;
    color: rgba(255,255,255,1);
    font-family: "NotoSansArmenian-Regular";
    font-size: 14px!important;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    line-height: 1.5rem;
}
@media (max-width: 768px) {
    .account-content-email-send{
        min-width: 90%;

    }
    .check-text{
        font-size: 16px!important;
    }

}
@media (max-height: 800px) {
    .check-info-text{
        margin-top:20px!important;
    }
    .check-text{
        margin-top:20px!important;
        font-size: 14px!important;

    }
}
