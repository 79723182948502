.account-dashboard {
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff;
    flex-shrink: 0;
    margin-left: 15px;
}
.dashboard-topic {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-heading {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
}
.dashboard-content {
    height: calc(100% - 60px);
}
.dashboard-filter {
    padding: 30px;
}
.dashboard-filter input {
    padding: 10px 0;
    width: 100%;
    border-bottom: 1px solid #d6d6d6;
    font-size: 16px;
}
.dashboard-filter input::placeholder {
    color: #b2b2b2;
}
.dashboard-filter input:focus {
    outline: none;
}
.dashboard-item {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
}

.dashboard-item.active {
    background-color: #ebebeb;
}
.dashboard-item-icons {
    position: relative;
    z-index: 1;
    display: none;
}
.dashboard-item.active .dashboard-item-icons {
    display: flex;
    align-items: center;
    gap: 20px;
}
.dashboard-arrow {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.right-empty-box{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-arrow svg {
    width: 20px;
    height: 20px;
}
.dashboard-main-btn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
}
.dashboard-main-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.dashboard-select {
    width: 100%;
    border: 1px solid #d6d6d6;
    border-radius: 23px;
    height: 45px;
    padding: 2px 15px;
}
.dashboard-select select {
    border: none;
    width: 100%;
    height: 100%;
    color: #8a8a8a;
}
.dashboard-select select:focus {
    outline: none;
}
.current-title {
    position: absolute;
    top: 80%;
    left: 35%;
    transform: translateX(-25%);
    padding: 10px;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
}
.dashboard-item:last-child .current-title {
    top: -80%;
}
.dashboard-item:first-child .current-title {
    top: 80%;
}
.dashboard-main-btn:hover + .current-title {
    visibility: visible;
    opacity: 1;
    transition: opacity .2s linear 2s;
}

.dashboard-list {
    height: calc(100% - 105px);
    overflow-y: auto;
    scrollbar-color: #a4aab5 #979797;
    scrollbar-width: thin;
}
.dashboard-list::-webkit-scrollbar {
    width: 8px;
}
.dashboard-list::-webkit-scrollbar-track {
    background-color: #e9e9e9;
}
.dashboard-list::-webkit-scrollbar-thumb {
    background-color: #979797;
    border-radius: 4px;
}

.account-dashboards {
    scrollbar-color: #a4aab5 #979797;
    scrollbar-width: thin;
}
.account-dashboards::-webkit-scrollbar {
    height: 8px;
}
.account-dashboards::-webkit-scrollbar-track {
    background-color: #e9e9e9;
}
.account-dashboards::-webkit-scrollbar-thumb {
    background-color: #979797;
    border-radius: 4px;
}


.account-dashboards-new {
    scrollbar-color: #a4aab5 #979797;
    scrollbar-width: thin;
}
.account-dashboards-new::-webkit-scrollbar {
    height: 8px;
}
.account-dashboards-new::-webkit-scrollbar-track {
    background-color: #e9e9e9;
}
.account-dashboards-new::-webkit-scrollbar-thumb {
    background-color: #979797;
    border-radius: 4px;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .dashboard-heading  {
        font-size: 15px;
    }
    .dashboard-filter input{
        font-size: 13px;
    }
    .dashboard-item{
        padding-right: 12px 20px;
    }
    .dashboard-main-btn{
        font-size: 13px;
    }
}