.container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.check-text {
    font-size: 26px !important;
    text-transform: unset;
    text-align: center;
    color: #fff;
}
.check-text:nth-child(2) {
    font-size: 26px !important;
    text-transform: unset;
    text-align: center;
    color: #fff;
    font-weight: bold;
}

.changePhone {
    border-bottom: 1px solid #FFFFFF;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
}
