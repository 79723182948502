.firstText {
    color: #BCB4B9;
    font-size: 16px;
    font-weight: 400;
}

.resendText {
    border-bottom: 1px solid transparent;
    text-transform: uppercase;
    color: #BCB4B9;
    font-weight: 500;
    font-size: 16px;
    margin-left: 10px;
}

.resend-container {
    display: flex;
    align-items: center;
}

.resendTextEnabled {
    border-bottom: 1px solid #FFFFFF;
    color: #fff;
    font-weight: 400;
    margin-left: 10px;
}

.countDown {
    margin-left: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
}

.black {
    color: #000
}
