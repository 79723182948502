.screenshot-btn img{
    filter: none!important;
}
.body-overlay-shot{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    scale: 2;
    background: rgb(243,242,247);
    background: linear-gradient(90deg, rgba(243,242,247,1) 0%, rgba(220,220,223,1) 50%, rgba(247,247,247,1) 100%);
    opacity: .5;
    animation-name: overlay-animate;
    animation-duration: .6s;
    animation-timing-function: linear;
    display: none;
}

.tooltip{
    width: calc(20px / 0.7);
    height: calc(20px / 0.7);
    background-color: #fff;
    position: absolute;
    top:-8px;
    left: 45.5%;
    transform: rotate(135deg);
}
@keyframes overlay-animate {
    from {opacity: .5;}
    to {opacity: 0;}
}